<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="approvalItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Status Approval</label>
            <Dropdown v-model="forms.status_approval" :options="dataDropdownStatusApproval" optionLabel="name" optionValue="code" placeholder="Select a Status" 
                :class="{ 'p-invalid': errorEdit.is_approved }" @change="clearReason"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.is_approved">{{errorEdit.is_approved[0]}}</small>
        </div>
        <div class="field" v-if="forms.status_approval == 'n'">
            <label>Rejected Reason</label>
            <InputText id="reject_reason" v-model="forms.reject_reason" :class="{ 'p-invalid': errorEdit.reject_reason }" placeholder="Write a Reason"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.reject_reason">{{errorEdit.reject_reason[0]}}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownStatusApproval: [
                {name: 'Approved', code: 'y'},
                {name: 'Rejected', code: 'n'},
            ],

            // filter
            forms: {
                status_approval: null,
                reject_reason: null,
            },

            // edit
            approvalItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        approvalItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.approvalItemDialog == true){
                        // 
                    }
                    this.clearForms();
                    this.$store.commit('setErrorEdit', {});
                }, 250);
            },
        },
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.approvalItemDialog = false;
		},
		clearReason() {
			if(this.forms.status_approval != 'Rejected'){
                this.forms.reject_reason = null;
            }
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/noo/approval',
                data: {
                    "noo_id" : this.item.noo_id,
                    "is_approved" : this.forms.status_approval,
                    "reject_reason" : this.forms.reject_reason,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.approvalItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Updated', life: 3000,});
            });
		},
        clearForms(){
            this.forms.status_approval = null;
            this.forms.reject_reason = null;
        }
    }
}
</script>