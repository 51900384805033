<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Period</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Max 30 Days'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Territory</label>
                    <Dropdown id="territory_id" dataKey="territory_id" v-model="filters.territory_id" :loading="loadingDropdownTerritory"
                        :options="dataDropdownTerritory"
                        optionLabel="territory_label" optionValue="territory_id" placeholder="Select Territory"
                        :filter="true" :showClear="true" @filter="searchDropdownTerritory($event, 'filter')" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="status">Status Approval</label>
                    <Dropdown v-model="filters.status" :options="dataDropdownStatusApproval" optionLabel="name" optionValue="code" placeholder="Select a Status" />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritory: false,

            // dataDropdown
            dataDropdownTerritory: null,
            dataDropdownStatusApproval: [
                {name: 'Pending', code: 'Pending'},
                {name: 'Approved', code: 'Approved'},
                {name: 'Rejected', code: 'Rejected'},
            ],

            // filter
            filters: {
                territory_id: null,
                status: "Pending",
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownTerritory('');
    },
    created(){
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownTerritory(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownTerritory = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownTerritory = res.data.data;
                            this.loadingDropdownTerritory = false;
                        } else if (purpose == null) {
                            this.dataDropdownTerritory = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Make sure the time range is correct or not empty');
            }
        }
    }
}
</script>

